import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-text">
        <p><strong>© M31 Biomedical AI. All rights Reserved.</strong></p>
        <p><a href="mailto:general@m31.bio">general@m31.bio</a></p>
      </div>
    </footer>
  );
}

export default Footer;