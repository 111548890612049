import React, { useState } from 'react';
import AuthorList from './AuthorList.js';

export default function Article({ article }) {
  
  return (
    <div className="article">
      <h2>
        <a href={article.link} target="_blank" rel="noopener noreferrer">
          {article.title}
        </a>
      </h2>
      <AuthorList authors={article.authors} />
      <div className="article-meta">
        <p><strong>Journal:</strong> {article.journal}</p>
        <p><strong>Publication:</strong> {article.date}</p>
      </div>
    </div>
  );
}