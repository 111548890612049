import { Link } from 'react-router-dom';
import logoimg from './images/M31LogoGrey.png'

// import './App.css';

export default function Navbar() {
  return (
    <nav className="nav-bar-wrapper">
      <div className="logo">
        <Link to="/">
          <img src={logoimg} alt="M31 Logo" className="logo-img" />
        </Link>
      </div>
      <ul className="nav-links">
        <li className="nav-item"><Link to="/Products">PRODUCTS</Link></li>
        <li className="nav-item"><Link to="/Services">SERVICES</Link></li>
        <li className="nav-item"><Link to="/Resources">RESOURCES</Link></li>
        <li className="nav-item"><Link to="/Contact">CONTACT</Link></li>
        <li className="nav-item"><Link to="/About">ABOUT</Link></li>
      </ul>
    </nav>
  );
}
