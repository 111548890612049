import React, { useState } from 'react';

export default function AuthorList({ authors }) {
  const [showAll, setShowAll] = useState(false);

  const formatAuthors = () => {
    if (showAll || authors.length <= 10) {
      return authors.map((author, index) => {
        const isLast = index === authors.length - 1;
        const isSecondLast = index === authors.length - 2;

        return (
          <span key={index}>
            {author}
            {isLast ? '' : isSecondLast ? ' & ' : ', '}
          </span>
        );
      });
    } else {
      const firstAuthors = authors.slice(0, 9);
      const lastAuthor = authors[authors.length - 1];

      return (
        <>
          {firstAuthors.map((author, index) => (
            <span key={index}>
              {author}
              {index < firstAuthors.length - 1 ? ', ' : ''}
            </span>
          ))}
          {', ... & '}
          {lastAuthor}
        </>
      );
    }
  };


  return (
    <div >
      <p style={{ marginBottom: '5px'}}><strong>Authors:</strong> {formatAuthors()}</p>
      {authors.length > 10 && (
        <button
          onClick={() => setShowAll(!showAll)}
          style={{
            marginTop: '0px',
            backgroundColor: '#f1f3f4',
            border: 'none',
            borderRadius: '8px',
            padding: '6px 12px',
            cursor: 'pointer',
            fontSize: '0.9rem',
            whiteSpace: 'nowrap',
            transition: 'width 0.2s ease',
            width: 'auto'
          }}
        >
          {showAll ? '− Show fewer authors' : '+ Show all authors'}
        </button>
      )}
    </div>
  );
}