import React, { useState } from 'react';

export default function NewsletterForm() {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted:', formData);
    // add your API submission logic here
  };

  return (
    <div className="newsletter-wrapper">
      <h2>Sign up for our newsletter</h2>
      <form onSubmit={handleSubmit} className="newsletter-form">

        <label htmlFor="email">Email<span style={{ color: 'red' }}>*</span></label>
        <input
          type="email"
          name="email"
          id="email"
          required
          value={formData.email}
          onChange={handleChange}
        />

        <label htmlFor="firstName">First name</label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />

        <label htmlFor="lastName">Last name</label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />

        <button type="submit">Subscribe</button>
      </form>
    </div>
  );
}