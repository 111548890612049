import './Home.css';
import Article from './helpers/Article.js';
import TeamSection from './helpers/TeamSection.js';
import NewsletterForm from './helpers/NewsletterForm.js';
import Footer from './helpers/Footer.js';
import headshot1 from '../images/headshot_bowang.png'
import headshot2 from '../images/jun_ma.jpeg'




const teamMembers = [
  {
    name: 'Bo Wang',
    role: 'Founder & CEO',
    description: ['CIFAR AI Chair', 'Canada Research Chair'],
    imgSrc: headshot1,
    twitter: 'https://x.com/BoWang87',
    linkedin: 'https://www.linkedin.com/in/bo-wang-a6065240/',
    googlescholar: 'https://scholar.google.ca/citations?user=37FDILIAAAAJ&hl=en'
  },
  {
    name: 'Jun Ma',
    role: 'Co-Founder & CTO',
    description: ['AI Scientist'],
    imgSrc: headshot2,
    twitter: 'https://x.com/JunMa_11',
    linkedin: 'https://www.linkedin.com/in/jun-ma-867b34224/',
    googlescholar: 'https://scholar.google.com.hk/citations?user=bW1UV4IAAAAJ&hl=en'
  }
];

export default function Home() {
    const articles = [
      {
        title: 'Segment anything in medical images',
        authors: ["Jun Ma", "Yuting He", "Feifei Li", "Lin Han", "Chenyu You", "Bo Wang"],
        journal: 'Nature Communication',
        date: 'January 22, 2024',
        link: 'https://www.nature.com/articles/s41467-024-44824-z'
      },
      {
        title: 'The multimodality cell segmentation challenge: toward universal solutions',
        authors: [
          "Jun Ma",
          "Ronald Xie",
          "Shamini Ayyadhury",
          "Cheng Ge",
          "Anubha Gupta",
          "Ritu Gupta",
          "Song Gu",
          "Yao Zhang",
          "Gihun Lee",
          "Joonkee Kim",
          "Wei Lou",
          "Haofeng Li",
          "Eric Upschulte",
          "Timo Dickscheid",
          "José Guilherme de Almeida",
          "Yixin Wang",
          "Lin Han",
          "Xin Yang",
          "Marco Labagnara",
          "Vojislav Gligorovski",
          "Maxime Scheder",
          "Sahand Jamal Rahi",
          "Carly Kempster",
          "Alice Pollitt",
          "Leon Espinosa",
          "Tâm Mignot",
          "Jan Moritz Middeke",
          "Jan-Niklas Eckardt",
          "Wangkai Li",
          "Zhaoyang Li",
          "Xiaochen Cai",
          "Bizhe Bai",
          "Noah F. Greenwald",
          "David Van Valen",
          "Erin Weisbart",
          "Beth A. Cimini",
          "Trevor Cheung",
          "Oscar Brück",
          "Gary D. Bader",
          "Bo Wang"
        ],
        journal: 'Nature Methods',
        date: 'March 26, 2024',
        link: 'https://www.nature.com/articles/s41592-024-02233-6'
      },
      {
        title: 'Towards foundation models of biological image segmentation',
        authors: ['Jun Ma', 'Bo Wang'],
        journal: 'Nature Methods',
        date: 'July 11, 2023',
        link: 'https://www.nature.com/articles/s41592-023-01885-0'
      }
    ];
    return (
      <div>
        <div className="front">
          <div className="front-text">
            <h1>Future of AI Biomedical Imaging</h1>
            <p>M31 is a non-profit research organization, pioneering 
              advancements in biomedical imaging through cutting-edge AI.</p>
          </div>
        </div>
        <div className="element-containter">
          <div className="description-photo-containter">
            <div className="description-containter">
              <h1>AI-powered, research-focused & patient-centered solutions to medical imaging analysis.</h1>
              <p>At M31 Biomedical AI, we combine scientific excellence with real-world impact to transform the future of medical imaging. Our expertise spans neurodegenerative disease research, custom AI model development, and radiology workflow enhancement—powered by cutting-edge foundation models. In collaboration with hospitals, universities, and pharmaceutical companies, we develop scalable, task-specific tools that power discovery, enhance diagnostics, and improve patient outcomes. <b>Every solution is designed with both scientific depth and operational practicality, tailored to meet the unique needs of our partners.</b></p>
              <button>Learn more about our services</button>
            </div>
            <div className="photo-container">
              <h1>insert photos</h1>
            </div>
          </div>
        </div>
        <div className="element-containter">
          <div className="icon-text-container">
            <div className="icon-text">
              <h1>Service 1 icon</h1>
              <h1>Service 1 title</h1>
              <p>Service 1 text</p>
            </div>
            <div className="icon-text">
              <h1>Service 2 icon</h1>
              <h1>Service 2 title</h1>
              <p>Service 2 text</p>
            </div>
            <div className="icon-text">
              <h1>Service 3 icon</h1>
              <h1>Service 3 title</h1>
              <p>Service 3 text</p>
            </div>
          </div>
        </div>

        <div className="element-containter">
          <h1>Recently published articles</h1>
          <div className="article-container">
            {articles.map((article, index) => (
              <Article key={index} article={article} />
            ))}
          </div>
        </div>
        <div className="element-containter">
          <h1>Meet our scientists</h1>
          <TeamSection members={teamMembers} />
        </div>
        <div className="element-containter">
          <NewsletterForm />
        </div>
        <Footer />
      </div>

    );
  }